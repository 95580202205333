
















































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component({})
export default class TransportSettingsNotification extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
  }

  valid = true
  loadingData = true
  saveIsLoading = false
  recipients = []
  isDisabled = false
  maximumRecipients = 5

  created() {
    this.getInitialData()
  }

  getInitialData() {
    this.$axios
      .get('/v4/site/notification/logistics/config')
      .then((response) => {
        const config = response?.data?.data
        this.recipients = config?.notification_email_recipients
          ? config?.notification_email_recipients.split(',').map(email => String(email).trim())
          : []
        this.isDisabled = !!config.notification_email_disabled
      })
      .finally(() => {
        this.loadingData = false
      })
  }

  removeRecipient(index) {
    this.recipients.splice(index, 1)
  }

  addRecipient() {
    this.recipients.push('')
  }

  saveConfig() {
    if (this.$refs.form.validate()) {
      this.saveIsLoading = true
      const data = {
        notification_email_recipients: (
          this.recipients
            .filter(email => email && email.trim())
            .map(email => email.trim())
          ).join(','),
        notification_email_disabled: this.isDisabled,
      }
      this.$axios
        .post('/v4/site/notification/logistics/config', data)
        .then((response) => {
          const config = response?.data?.data
          this.recipients = config?.notification_email_recipients
            ? config?.notification_email_recipients.split(',').map(email => String(email).trim())
            : []
          this.isDisabled = !!config.notification_email_disabled

          vxm.alert.success({
            content: this.$t('c:common:Successfully saved') as string,
            title: this.$t('c:common:Success') as string,
          })
        })
        .catch((error) => {
          vxm.alert.error({
            content: String(error?.response?.data?.error?.message || this.$t('Unable to save settings, please contact support')) as string,
            title: this.$t('c:common:Error') as string,
          })
        })
        .finally(() => {
          this.saveIsLoading = false
        })
    }
  }
}
